<template>
  <page-view bg-color="#f5f5f5">
    <template v-slot:nav>
      <nav-bar title="付款码" />
    </template>
    <div class="content">
      <div class="img-box">
        <van-image width="81.06667vw" min-height="81.06667vw" :src="imgFullFix(QrCode, 'large')" fit="cover" />
      </div>
      <div class="text-center text-color gray mt-20  pl-10 pr-10">收款码可免费申请或保存自行打印，张贴在店铺内推荐用户扫码付款。</div>
    </div>
    <template v-slot:footer>
      <div class="footer-btn-group">
        <van-button color="#E4AF79" @click="savePic">保存到相册</van-button>
        <!-- <van-button type="warning" @click="goBuy">免费申请</van-button> -->
      </div>
    </template>
  </page-view>
</template>
<style lang="scss" scoped>
  @import './index.scss';
</style>
<script>
import { Button, Image } from 'vant'
import PageView from '@/layouts/PageView'
import NavBar from '@/components/nav-bar'
import getQrCode from '../../model/get-QrCode/index'
import { mallMaterialBuyUrl } from '@/config/net'
import { imgFullFix } from '@/utils/img'

export default {
  data () {
    return {
      QrCode: ''
    }
  },
  components: { PageView, NavBar, [Button.name]: Button, [Image.name]: Image },
  created () {
  },
  mounted () {
    getQrCode.getURL().then(res => {
      this.QrCode = res
    })
  },
  beforeDestroy () {
  },
  methods: {
    imgFullFix,
    goBuy () {
      this.$store.dispatch('userInfo/pvCount', 109)
      window.location.href = mallMaterialBuyUrl
    },
    savePic () {
      this.$store.dispatch('userInfo/pvCount', 108)
      window.$fe_bridge.shareAction.savePicture({
        title: '',
        link: '',
        imgUrl: this.QrCode,
        description: ''
      }, () => {
      })
    }
  },
  computed: {},
  watch: {}
}
</script>
