import * as urls from '@/config/net'
import api from '@/tools/api-tools'

class getQrCode {
  static getURL () {
    return api.get(`${urls.apiUrl}/paymentcode/qrcode_image_url?biddingType=2`)
      .then(res => {
        if (res.status === 200) {
          // 替换为https开头
          let url = res.data
          const urlArr = url.split(':')
          url = 'https:' + urlArr[1]
          return url
        }
      })
  }
}

export default getQrCode
